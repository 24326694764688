<template>
    <modal ref="modalCopiarDestinatarios" titulo="Copiar lista destinatarios" no-guardar no-aceptar adicional="Importar" @adicional="onSave()">
        <div class="row mx-0 justify-center">
            <div class="col-11 f-15 text-general mb-4">
                Seleccione el listado de destinatarios a partir de un <br /> lanzamiento
            </div>
            <div class="col-11">
                <label class="f-14 pl-3 text-general">Carpeta</label>
                <el-select v-model="carpeta" size="small" class="w-100" @change="listarCampanasCopiar()">
                    <el-option
                    v-for="item in carpetas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-11 mt-4">
                <label class="f-14 pl-3 text-general">Campa</label>
                <el-select v-model="campana" size="small" class="w-100" @change="listarLanzamientoCopiar()">
                    <el-option
                    v-for="item in campanas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-11 mt-4">
                <label class="f-14 pl-3 text-general">Lanzamiento</label>
                <el-select v-model="lanzamiento" size="small" class="w-100">
                    <el-option
                    v-for="item in lanzamientos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones';
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            carpeta: '',
            campana: '',
            lanzamiento: '',
            carpetas: [],
            campanas: [],
            lanzamientos: [],

        }
    },
    computed: {
        ...mapGetters({
            tipo: 'publicidad/notificaciones/tipo',
        })
    },
    methods: {
        toggle(){
            this.limpiar()
            this.listarCarpetasCopiar()
            this.$refs.modalCopiarDestinatarios.toggle();
        },
    
        async listarCarpetasCopiar(){
            try {
                let params = {
                    tipo: this.tipo
                }
                const { data } = await notificaciones.listarCarpetasCopiar(params);
                this.carpetas = data.carpetas
            } catch (error){
                this.error_catch(error)
            }
        },
        async listarCampanasCopiar(){
            try {
                this.campana = ''
                this.lanzamiento = ''
                this.campanas = []
                this.lanzamientos = []
                const { data } = await notificaciones.listarCampanasCopiar(this.carpeta);
                this.campanas = data.campanas
            } catch (error){
                this.error_catch(error)
            }
        },
        async listarLanzamientoCopiar(){
            try {
                this.lanzamiento = ''
                this.lanzamientos = []
                const { data } = await notificaciones.listarLanzamientoCopiar(this.campana);
                this.lanzamientos = data.lanzamientos.filter(el => el.id != this.$route.params.id_lanzamiento)
            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.carpeta = ''
            this.campana = ''
            this.lanzamiento = ''
            this.carpetas = []
            this.campanas = []
            this.lanzamientos = []
        },
        async onSave(){
            try {
                let model = {
                    desde: this.lanzamiento,
                    para: this.$route.params.id_lanzamiento
                }
                const { data } = await notificaciones.copiarUsuariosLanzamientos(model);
                if (data.exito){
                    this.$emit('saved')
                    this.notificacion('', 'Copiado correctamente', 'success')
                    this.$refs.modalCopiarDestinatarios.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        }

    }
}
</script>